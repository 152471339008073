<template>
  <div class="min-h-screen">
    <slot />
    <YmiNotifications
      :mapped-store="notieStore"
      default-position="top-right"
    />
  </div>
</template>

<script setup>
import { useNotieStore } from '~/stores/notifications';
const notieStore = useNotieStore();

onMounted(() => {
  document.body.classList.add('overflow-hidden');
});

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden');
});
</script>
